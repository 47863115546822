import React from 'react';
import styled from 'styled-components';
import { fontSize } from '../styles';
import { Link } from './ui';

const StyledBreadcrumbs = styled.div`
  margin: 20px 0;
`;

const StyledList = styled.ol``;

const StyledItem = styled.li`
  display: inline;
  ${fontSize(14)};
  line-height: 1.7;

  &:before {
    content: '/';
    margin: 0 10px;
  }

  &:first-child {
    &:before {
      content: none;
    }
  }
`;

const StyledLink = styled(Link)`
  text-decoration: underline;
`;

const Breadcrumbs = ({ breadcrumbs, currentPageTitle, ...props }) => (
  <StyledBreadcrumbs {...props}>
    <StyledList>
      <StyledItem>
        <StyledLink to="/">Home</StyledLink>
      </StyledItem>
      {breadcrumbs &&
        breadcrumbs.map(({ slug, text }, i) => (
          <StyledItem id={`breadcrumb-${i}`}>
            <StyledLink to={slug}>{text}</StyledLink>
          </StyledItem>
        ))}
      <StyledItem>{currentPageTitle}</StyledItem>
    </StyledList>
  </StyledBreadcrumbs>
);

export default Breadcrumbs;
